import {BooleanField, Datagrid, InfiniteList, TextField, useTranslate} from "react-admin";
import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import {useFormContext} from "react-hook-form";
import BulkActionButtonsToPrinter from "./BulkActionToPrinter";

const PrinterEditDetails = ({formData, setSelectedIds}) => {
    const translate = useTranslate()
    const NoProducts = () => <Typography>{translate("products.empty")}</Typography>;
    const [printerName, setPrinterName] = useState(formData.name)
    const [printerSerialNumber, setPrinterSerialNumber] = useState(formData.serialNumber)
    const {setValue} = useFormContext()

    useEffect(() => {
        setValue("name", printerName);
    }, [printerName]);

    useEffect(() => {
        setValue("serialNumber", printerSerialNumber);
    }, [printerSerialNumber]);

    return (
        <>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <MuiTextField value={printerName} label={translate("printer.name")} onChange={
                    (e) => {
                        setPrinterName(e.target.value)
                    }
                }/>
                <MuiTextField value={printerSerialNumber} label={translate("printer.serialNumber")} onChange={
                    (e) => {
                        setPrinterSerialNumber(e.target.value)
                    }
                }/>
            </Box>
            <Typography sx={{marginTop: '10px', marginBottom: '10px'}}>Termékek hozzáadása a nyomtatóhoz</Typography>
            <Box sx={{maxHeight: '600px', overflow: 'auto'}}>
                <InfiniteList empty={<NoProducts/>} exporter={false} resource={"v1/order/printer/products"}
                              title={"Termékek"} pagination={false} perPage={2000}
                              filter={{orderPrinterId: formData.id, size: 2000}}
                >
                    <Datagrid optimized={true}
                              bulkActionButtons={<BulkActionButtonsToPrinter setSelectedIds={setSelectedIds}/>}>
                        <TextField source="id" label={"printer.id"}/>
                        <TextField source="name" label={"printer.name"}/>
                        <BooleanField source="isTakeawayAllowed" label={"printer.isTakeawayAllowed"}/>
                    </Datagrid>
                </InfiniteList>
            </Box>
        </>
    )
}

export default PrinterEditDetails;