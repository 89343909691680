import Autocomplete from "@mui/material/Autocomplete";
import React, {useEffect, useState} from "react";
import {
    Button,
    Datagrid,
    DateField,
    FunctionField,
    List,
    useLocaleState,
    useTranslate,
    useNotify
} from "react-admin";
import {Box, CircularProgress, DialogTitle, TextField as MuiTextField} from "@mui/material";
import {PinkPagination} from "../../../../components/PinkPagination";
import {EmptyPage} from "../../../../components/EmtpyPage";
import dataProvider from "../../../../components/dataProvider";
import {getValidPrivilegesIds} from "../../../../components/authentication/PermissionHandler";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const PurchaseHistory = ({permissions}) => {
    const [companies, setCompanies] = useState([])
    const [waiting, setWaiting] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [rowId, setRowId] = useState(0)
    const [isUniqueContract, setIsUniqueContract] = useState(false)
    const translate = useTranslate()
    const locale = useLocaleState();
    const hasStorno = getValidPrivilegesIds("PURCHASE", "STORNO", true).some(r => permissions.includes(r))
    const [open, setOpen] = useState(false)
    const notify = useNotify()

    const getCompanies = () => {
        dataProvider.get('purchase/companies')
            .then(({data}) => {
                setCompanies(data.list)
                const selectedCompany = localStorage.getItem(`purchaseHistoryPosSetId`)
                if (selectedCompany) {
                    setSelectedCompany(data.list.find(c => c.id === parseInt(selectedCompany)))
                }
            })
            .catch(reason => {
                console.log(reason)
            })

    }
    useEffect(() => {
        getCompanies()
    }, [])

    const handleCancel = () => {
        setWaiting(true)
        setOpen(false)
        const data = {purchaseId: rowId}
        if (isUniqueContract) {
            dataProvider.create(`purchase/stornoUnique`, {data})
                .then(({data}) => {
                    setWaiting(false)
                    window.location.reload()
                })
                .catch(reason => {
                    setWaiting(false)
                    notify('purchaseHistory.error', {type: "error"})
                })
        }
        else {
            dataProvider.create(`purchase/storno`, {data})
                .then(({data}) => {
                    if (data.response) {
                        queryCallback(data.response)
                    }
                })
                .catch(reason => {
                    notify('purchaseHistory.inProgress', {type: "error"})
                    setWaiting(false)
                })
        }
    }

    const queryCallback = (query) => {
        const interval = setInterval(() => {
            dataProvider.get('purchase/query', {orderId: query})
                .then(({data}) => {
                    if (data.transactions[0].status === "FINISHED" || data.transactions[0].status === "SUCCESS" || data.transactions[0].status === "REFUND") {
                        setWaiting(false)
                        clearInterval(interval)
                        notify('purchaseHistory.success', {type: "success"})
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                    }
                    else if (data.transactions[0].status === "FAIL" || data.transactions[0].status === "CANCELED" || data.transactions[0].status === "ERROR" || data.transactions[0].status === "TIMEOUT" || data.transactions[0].status === "FRAUD") {
                        setWaiting(false)
                        clearInterval(interval)
                        notify('purchaseHistory.error', {type: "error"})
                    }
                })
                .catch(reason => {
                    console.log(reason)
                })
        }, 5000)
        return () => clearInterval(interval)
    }

    const handleClick = (id, isUniqueContract) => {
        setOpen(true)
        setRowId(id)
        setIsUniqueContract(isUniqueContract)
    }

    return (
        <>
            <Autocomplete
                value={selectedCompany}
                renderInput={(params) => <MuiTextField {...params} variant="standard"
                                                       label={translate("purchase.company")}/>}
                options={companies}
                defaultValue={selectedCompany}
                getOptionLabel={(option) => option.name || ""}
                onChange={(e, value) => {
                    if (value) {
                        localStorage.setItem(`purchaseHistoryPosSetId`, value.id)
                    }
                    setSelectedCompany(value)
                }}
                sx={{width: '300px'}}
            />
            {selectedCompany?.id && <List
                pagination={<PinkPagination trigger={selectedCompany?.id}/>}
                filterDefaultValues={{posSetId: selectedCompany?.id}}
                title={"purchaseHistory.title"} empty={<EmptyPage text={"suppliers.empty"} createEnabled={true}/>}>
                <Datagrid bulkActionButtons={false}>
                    <FunctionField label={"purchaseHistory.package"} render={record => record.names.join(",")}/>
                    <DateField source="purchaseTimestamp" showTime locales={locale[0]} label={"purchaseHistory.purchaseDate"}/>
                    <DateField source="validUntil" showTime locales={locale[0]} label={"purchaseHistory.expirationDate"}/>
                    {hasStorno && <FunctionField label={"purchaseHistory.cancel"} render={record => {
                       return record.isStorno ?
                           <Box sx={{ m: 1, position: 'relative', display: 'flex', justifyContent: "center" }}>
                                <Button variant={"contained"} disabled={true} color={"primary"}>{translate("purchaseHistory.canceled")}</Button>
                           </Box>
                            :
                           <Box sx={{ m: 1, position: 'relative', display: 'flex', justifyContent: "center" }}>
                               <Button onClick={()=>{handleClick(record.id, record.isUniqueContract)}} disabled={waiting} variant={"contained"} color={"secondary"}>{translate("purchaseHistory.cancel")}</Button>
                               <Dialog
                                   open={open}
                                   onClose={()=>{setOpen(false)}}
                                   aria-labelledby="alert-dialog-title"
                                   aria-describedby="alert-dialog-description"
                               >
                                   <DialogTitle id="alert-dialog-title">
                                       {translate("purchaseHistory.dialogTitle")}
                                   </DialogTitle>
                                   <DialogContent>
                                       <DialogContentText id="alert-dialog-description">
                                           {translate("purchaseHistory.dialogContent")}
                                       </DialogContentText>
                                   </DialogContent>
                                   <DialogActions>
                                       <Button onClick={()=>{setOpen(false)}}>{translate("purchaseHistory.dialogCancel")}</Button>
                                       <Button onClick={handleCancel}>
                                           {translate("purchaseHistory.dialogConfirm")}
                                       </Button>
                                   </DialogActions>
                               </Dialog>
                               {waiting && (
                                   <CircularProgress
                                       size={24}
                                       sx={{
                                           position: 'absolute',
                                           top: '50%',
                                           left: '50%',
                                           marginTop: '-12px',
                                           marginLeft: '-12px',
                                       }}
                                   />
                               )}
                           </Box>

                    }}/>}
                </Datagrid>
            </List>}
        </>
    )
}

export default PurchaseHistory