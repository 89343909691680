import React, {useEffect, useState} from "react"
import dataProvider from "./dataProvider";
import {TextField, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {useTranslate} from "react-admin";
import removeAccents from "./removeAccents";

export default function SelectCompanyWithBusinessUnitInput({getResource, setPosSet, companySelectDisabled, setUserLimit, setIsShowRedirect, setSimplifiedBu, setTrigger}) {
    const translate = useTranslate()
    const [companiesWithBu, setCompaniesWithBu] = useState([])
    const [selectedPosSet, setSelectedPosSet] = useState(null)

    useEffect(() => {
        if (getResource === "product" && selectedPosSet !== "undefined" && selectedPosSet !== null && companiesWithBu.length !== 0) {
                    for (const posSet of companiesWithBu) {
                        const Bu = posSet.id === selectedPosSet.id ? posSet :null
                        if (Bu) {
                            setSimplifiedBu(Bu.simplified || false)
                            break
                        }
                    }
                }
        if (setIsShowRedirect && selectedPosSet !== "undefined" && selectedPosSet !== null) {
            if (getResource === "user") {
                dataProvider.get("user/technicalUserCount",  {posSetId: selectedPosSet?.id})
                    .then(value => {
                        selectedPosSet?.userLimit === null || companiesWithBu.find(e => e.id === selectedPosSet.parentId)?.userLimit === null ? setUserLimit(true) : setUserLimit(selectedPosSet.parentId? value.data < companiesWithBu.find(e => e.id === selectedPosSet.parentId)?.userLimit : value.data < selectedPosSet?.userLimit)
                    })
            }
            if (selectedPosSet?.posSetSelectorPrivileges.getPrivilegeCompany) {
                setIsShowRedirect(false)
            }else {
                setIsShowRedirect(true)
            }
        }
        if (getResource === "priceList" && selectedPosSet !== "undefined" && selectedPosSet !== null && companiesWithBu.length !== 0) {
            setCompaniesWithBu(companiesWithBu.filter(item => item.simplified !== true));
        }        
        if (getResource === "priceListToPosSet" && selectedPosSet !== "undefined" && selectedPosSet !== null && companiesWithBu.length !== 0) {
            setCompaniesWithBu(companiesWithBu.filter(item => item.simplified !== true));
        }
        if (getResource === "quickMenu" && selectedPosSet !== "undefined" && selectedPosSet !== null && companiesWithBu.length !== 0) {
            const BU = companiesWithBu.find(e => e.id === selectedPosSet.id)
            setSimplifiedBu(BU.simplified)
            setTrigger(prevState => !prevState)
        }
    }, [selectedPosSet]);

    useEffect(() => {
        dataProvider.get(`${getResource}/pos-sets`)
            .then(value => {
                //value.data[2].posSetSelectorPrivileges.getPrivilege = false //1 cég letiltáshoz teszt
                let data = []
                value.data.list?.map(element => {
                    element.company = true
                    data.push(element)
                    element?.children.map(el => {
                        el.parentCompany = element.name
                        data.push(el)
                    })
                })
                setCompaniesWithBu(data)
                const firstSelectable = data.find(it => isSelectable(it))
                const selectedPosSetId = (!!parseInt(value.data?.defaultPosSetId)) ?
                    parseInt(value.data.defaultPosSetId) :
                    localStorage.getItem(`${getResource}PosSetId`) || firstSelectable?.id

                if (data.map( it => it.id).includes(parseInt(selectedPosSetId))
                    && isSelectable(data.find(e => e.id === parseInt(selectedPosSetId)))
                    && data.length !== 0
                ) {
                    setPosSet(parseInt(selectedPosSetId))
                    setSelectedPosSet(data.find(e => e.id === parseInt(selectedPosSetId)))
                    if (getResource === "quickMenu") {
                        setSimplifiedBu(data.find(e => e.id === parseInt(selectedPosSetId)).simplified)
                    }
                } else {
                    setPosSet(0)
                }
            })
            .catch(reason => {
                setCompaniesWithBu(null)
            })
    }, [])
    const isSelectable = (it) => {
        return (companySelectDisabled && !it.hasOwnProperty('parentId')) ? false : it.posSetSelectorPrivileges.getPrivilege
    }

    const onChange = (event, value) => {
        setPosSet(value.id)
        setSelectedPosSet(value)
    }

    const optionRenderer = (props, choice) => {
        return (
            <li {...props} key={choice.id}>
                <Typography style={{paddingLeft: choice.parentCompany ? "30px" : "3px"}}>
                    {choice.name}
                </Typography>
            </li>
        )
    }

    const filterOptions = (options, state) =>
    {
        if(state.inputValue !== "" && companiesWithBu.length !== 0)
        {
            const inputValue = removeAccents(state.inputValue)
            return companiesWithBu?.filter(elem => {return !!(
                removeAccents(elem.name).includes(inputValue) ||
                removeAccents(elem.parentCompany)?.includes(inputValue) ||
                (elem.company && elem.children?.find(ch => removeAccents(ch.name)?.includes(inputValue)))
            )})
        }
        return companiesWithBu
    }

    return <Autocomplete
        options={companiesWithBu}
        getOptionLabel={option => option.name || ""}
        renderOption={optionRenderer}
        onChange={onChange}
        value={selectedPosSet}
        renderInput={params => (
            <TextField
                {...params}
                variant="standard"
                label={companySelectDisabled ? translate("hierarchySingleSelector.selectBu") : translate("hierarchySingleSelector.selectCompanyOrBu")}
                fullWidth
            />
        )}
        getOptionDisabled={(option) => {
            return (!option.posSetSelectorPrivileges.getPrivilege || (companySelectDisabled && option.company))
        }}
        style={{width: "300px"}}
        disableClearable
        filterOptions={filterOptions}
    />
}

