import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardActions, CardContent, Checkbox, IconButton, TextField, Typography} from "@mui/material";
import {Title, useNotify, useRedirect, useTranslate} from "react-admin";
import {DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid";
import {ThemeProvider} from "@mui/material/styles";
import {muiDatagridTheme} from "../../../../components/MuiDatagridTheme";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import dataProvider from "../../../../components/dataProvider";
import Autocomplete from "@mui/material/Autocomplete";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import {useLocaleState} from "react-admin";
import bigDecimal from "js-big-decimal";
import SaveBankCard from "../Cards/SaveBankCard";
import Resignation from "../Resignation/Resignation";
import {getValidPrivilegesIds} from "../../../../components/authentication/PermissionHandler";
import CardWithNoCompany from "./CardWithNoCompany";
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from "@mui/material/Tooltip";

const Purchase = ({Resource, setActiveStep, permissions}) => {
    const [companies, setCompanies] = useState([])
    const [quantity, setQuantity] = useState(null)
    const [selectedPackage, setSelectedPackage] = useState({
        lite: false,
        standard: false,
        pro: false,
        proPlus: false,
    })
    const [remaining, setRemaining] = useState(0)
    const [sum, setSum] = useState(0)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [rows, setRows] = useState([])
    const [support, setSupport] = useState([])
    const [email, setEmail] = useState('')
    const [tokenAmount, setTokenAmount] = useState(0)
    const [selectedSupport, setSelectedSupport] = useState(null)
    const [paymentUrl, setPaymentUrl] = useState(null)
    const [card, setCard] = useState(null)
    const [isFirstPurchase, setIsFirstPurchase] = useState(true)
    const [voucher, setVoucher] = useState("")
    const [validVoucher, setValidVoucher] = useState(false)
    const translate = useTranslate()
    const [selectedDate, setSelectedDate] = useState(dayjs())
    const [loading, setLoading] = useState(true)
    const notify = useNotify()
    const locale = useLocaleState()
    const hasUpdate = getValidPrivilegesIds("PURCHASE", "UPDATE",true).some(r => permissions?.includes(r))
    const redirect = useRedirect()

    const handleCheckboxChange = (e, name) => {
        setSelectedPackage(prevState => {
            Object.keys(prevState).forEach(key => {
                key !== name ? prevState[key] = false : prevState[key] = e.target.checked
            })
            return {...prevState}
        })

        if (e.target.checked && name !== 'lite') {
            setSum((rows[0][name] * quantity) + (selectedSupport.netPrice * quantity))
        } else {
            setSum(0)
            setQuantity(1)
            setSelectedSupport(support[0])
        }
    }

    useEffect(() => {
        dataProvider.get(`${Resource}/companies`)
            .then(({data}) => {
                if (!localStorage.getItem(`${Resource}PosSetId`)) {
                    localStorage.setItem(`${Resource}PosSetId`, data.defaultPosSetId)
                }
                setCompanies(data.list)
                setSelectedCompany(data.list.find(company => company.id === parseInt(localStorage.getItem(`${Resource}PosSetId`))))
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        dataProvider.get(`${Resource}/table`)
            .then(({data}) => {
                const firstRows = [
                    {
                        id: 0,
                        name: translate("purchase.price"),
                        lite: data.packages.find(p => p.name === "LITE").netPrice,
                        standard: data.packages.find(p => p.name === "STANDARD").netPrice,
                        pro: data.packages.find(p => p.name === "PROFESSIONAL").netPrice,
                        proPlus: data.packages.find(p => p.name === "PROFESSIONAL PLUS").netPrice,
                        liteOriginal: data.packages.find(p => p.name === "LITE").originalNetPrice,
                        standardOriginal: data.packages.find(p => p.name === "STANDARD").originalNetPrice,
                        proOriginal: data.packages.find(p => p.name === "PROFESSIONAL").originalNetPrice,
                        proPlusOriginal: data.packages.find(p => p.name === "PROFESSIONAL PLUS").originalNetPrice,
                    },
                ]
                const arrangedModules = data.modules.sort((a, b) => a.id - b.id)
                const otherRows = arrangedModules.map((module, index) => {
                    const res = {
                        id: module.id,
                        name: module.name,
                        description: module.description,
                        lite: data.packages.find((p => p.name === "LITE")).modules.some(m => m === module.id),
                        standard: data.packages.find((p => p.name === "STANDARD")).modules.some(m => m === module.id),
                        pro: data.packages.find((p => p.name === "PROFESSIONAL")).modules.some(m => m === module.id),
                        proPlus: data.packages.find((p => p.name === "PROFESSIONAL PLUS")).modules.some(m => m === module.id),
                        netPrice: module.netPrice,
                    }
                    if (module.name === "Felhasználó modul") {
                        res.technicalUserCountLite = data.packages.find((p => p.name === "LITE")).technicalUserCount
                        res.technicalUserCountStandard = data.packages.find((p => p.name === "STANDARD")).technicalUserCount
                        res.technicalUserCountPro = data.packages.find((p => p.name === "PROFESSIONAL")).technicalUserCount
                        res.technicalUserCountProPlus = data.packages.find((p => p.name === "PROFESSIONAL PLUS")).technicalUserCount
                    }
                    return res
                })
                setRows([...firstRows, ...otherRows])
                setSupport(data.supports)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (selectedCompany) {
            dataProvider.get(`${Resource}`, {companyId: selectedCompany.id})
                .then(res => {
                    setQuantity(res.data.licenseCount ? res.data.licenseCount : 1)
                    setSelectedPackage({
                        lite: res.data.packageId === 1,
                        standard: res.data.packageId === 2,
                        pro: res.data.packageId === 3,
                        proPlus: res.data.packageId === 4,
                    })
                    setSum(res.data.monthlyNetPrice)
                    setRemaining(res.data.remainingNetPriceFromOldLicense)
                    setCard(res.data.card)
                    setSelectedSupport(res.data.supportId ? support.find(s => s.id === res.data.supportId) : support[0])
                    if (res.data.email) {
                        setEmail(res.data.email)
                    } else {
                        setEmail("")
                    }
                    if (res.data.maxTokenAmount) {
                        setTokenAmount(res.data.maxTokenAmount)
                    } else {
                        setTokenAmount(0)
                    }
                    if (res.data.id && res.data.packageId !== 1) {
                        setIsFirstPurchase(false)
                    } else {
                        setIsFirstPurchase(true)
                    }
                    if (res.data.paymentUrl) {
                        setPaymentUrl(res.data.paymentUrl)
                    } else {
                        setPaymentUrl(null)
                    }
                    if (res.data.orderId) {
                        dataProvider.get(`license/query`, {orderId: res.data.orderId})
                            .then(res => {

                            })
                            .catch(err => {
                                console.log(err)
                            })
                    }

                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [selectedCompany])

    const continueToPay = () => {
        if (paymentUrl) {
            redirect(paymentUrl)
        }
        let netPrice = new bigDecimal((rows[0][Object.keys(selectedPackage).find(key => selectedPackage[key])] * parseInt(quantity) - remaining) + (selectedSupport.netPrice * parseInt(quantity)))
        const data = {
            company: selectedCompany,
            licenseCount: parseInt(quantity),
            netPrice: netPrice.getValue(),
            monthlyNetPrice: sum,
            packageId: Object.keys(selectedPackage).find(key => selectedPackage[key]) === "lite" ? 1 : Object.keys(selectedPackage).find(key => selectedPackage[key]) === "standard" ? 2 : Object.keys(selectedPackage).find(key => selectedPackage[key]) === "pro" ? 3 : 4,
            expirationDate: null,
            support: selectedSupport,
            email: email,
            maxTokenAmount: tokenAmount,
            voucher: voucher
        }
        localStorage.setItem('purchaseData', JSON.stringify(data))
        setActiveStep(1)
    }
    const sendPurchase = (type) => {
        let netPrice = new bigDecimal((rows[0][Object.keys(selectedPackage).find(key => selectedPackage[key])] * parseInt(quantity) - remaining) + (selectedSupport.netPrice * parseInt(quantity)))
        netPrice = netPrice.round(2)
        let endpoint = Resource
        const data = {
            companyId: selectedCompany.id,
            licenseCount: parseInt(quantity),
            netPrice: netPrice.getValue(),
            monthlyNetPrice: sum,
            packageId: Object.keys(selectedPackage).find(key => selectedPackage[key]) === "lite" ? 1 : Object.keys(selectedPackage).find(key => selectedPackage[key]) === "standard" ? 2 : Object.keys(selectedPackage).find(key => selectedPackage[key]) === "pro" ? 3 : 4,
            expirationDate: Resource === 'manageLicense' ? selectedDate : null,
            supportId: selectedSupport.id
        }
        if (type === 'resignation') {
            endpoint = "license/simple"
            setSelectedPackage(prevSelectedPackage => {
                Object.keys(prevSelectedPackage).forEach(key => {
                    key === 'lite' ? prevSelectedPackage[key] = true : prevSelectedPackage[key] = false
                })
                return prevSelectedPackage
            })
            const resignationNetPrice = new bigDecimal(0 - remaining)
            const resignationGrossPrice = new bigDecimal(0 - remaining).multiply(new bigDecimal(1.27))
            setSum(0)
            setQuantity(1)
            data.monthlyNetPrice = 0
            data.licenseCount = 1
            data.packageId = 1
            data.supportId = 1
            data.netPrice = resignationNetPrice.getValue()
            data.grossPrice = resignationGrossPrice.round(2, bigDecimal.RoundingModes.HALF_UP).getValue()
            data.monthlyGrossPrice = 0
            data.email = email
        }
        dataProvider.create(`${endpoint}`, {data})
            .then(res => {
                type === 'resignation' ? notify("resignation.success", {type: "success"}) : notify("purchase.success", {type: "success"})
            })
            .catch(err => {
                notify("purchase.error", {type: "error"})
            })
    }

    const voucherValidation = () => {
        const packageId =  Object.keys(selectedPackage).find(key => selectedPackage[key]) === "lite" ? 1 : Object.keys(selectedPackage).find(key => selectedPackage[key]) === "standard" ? 2 : Object.keys(selectedPackage).find(key => selectedPackage[key]) === "pro" ? 3 : 4
        dataProvider.get(`license/invoices`, {companyId: selectedCompany.id , packageId: packageId , supportId: selectedSupport.id , licenseCount: quantity  , voucherCode: voucher})
            .then(res => {
                const invoice = res.data[0].invoiceItems
                const valid = invoice.some(item =>
                    item.name.startsWith("Kupon fix kedvezmény") ||
                    item.name.startsWith("Kupon százalékos kedvezmény")
                );
                if (valid){
                    notify("purchase.voucherSuccess", {type: "success", autoHideDuration: 4000})
                }else{
                    notify("purchase.voucherError", {type: "warning", autoHideDuration: 4000})
                }
                setValidVoucher(true)
            })
            .catch(reason => {
                if (reason.message === "cannot_find_voucher_by_code") {
                    notify("purchase.voucherNotFound", {type: "warning", autoHideDuration: 4000})
                } else if (reason.message === "used_voucher") {
                    notify("purchase.usedVoucher", {type: "warning", autoHideDuration: 4000})
                } else if (reason.message === "voucher_is_not_usable_for_package") {
                    notify("purchase.badVoucher", {type: "warning", autoHideDuration: 4000})
                } else if (reason.message === "voucher_is_not_active") {
                    notify("purchase.isNotActive", {type: "warning", autoHideDuration: 4000})
                }else{
                    notify("purchase.voucherError", {type: "warning", autoHideDuration: 4000})
                }
                setValidVoucher(false)
            })
    }

    const columns = [
        {
            field: 'name',
            headerName: '',
            width: 350,
            sortable: false,
            renderCell: (param) => <div style={{width: '100%', textAlign: "center"}}>
                <Typography sx={{fontWeight: 'bold'}}>{param.value}</Typography>
                <Typography sx={{fontWeight: 'light', fontSize: '12px'}}>{param.row.description}</Typography>
                {param.value === "Felhasználó modul" &&
                    <Typography sx={{fontWeight: 'bold'}}>{translate("purchase.tech")}</Typography>}
            </div>
        },
        {
            field: 'lite',
            renderHeader: (params) => (
                <div>
                    <span>{translate("purchase.lite")}</span>
                    <Checkbox checked={selectedPackage.lite} onChange={(e) => {
                        handleCheckboxChange(e, 'lite')
                    }}/>
                </div>
            ),
            headerAlign: 'center',
            width: 170,
            sortable: false,
            renderCell: (param) => <div style={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%"
            }}>
                {typeof param.value === "boolean" ? (param.value ?
                    <DoneIcon color="primary"/> : <ClearIcon opacity={0.2}/>) : param.row.name === "Ár" &&
                    <Typography sx={{textDecoration: 'line-through'}}>{param.row.liteOriginal} EUR</Typography>}
                {param.row.name === "Felhasználó modul" && <Typography>{param.row.technicalUserCountLite}</Typography>}
                {param.row.name === "Ár" && <Typography>{param.value} EUR</Typography>}
            </div>
        },
        {
            field: 'standard',
            renderHeader: (params) => (
                <div>
                    <span>{translate("purchase.standard")}</span>
                    <Checkbox checked={selectedPackage.standard} onChange={(e) => {
                        handleCheckboxChange(e, 'standard')
                    }}/>
                </div>
            ),
            headerAlign: 'center',
            width: 170,
            sortable: false,
            renderCell: (param) => <div style={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%"
            }}>
                {typeof param.value === "boolean" ? (param.value ?
                    <DoneIcon color="primary"/> : <ClearIcon opacity={0.2}/>) : param.row.name === "Ár" &&
                    <Typography sx={{textDecoration: 'line-through'}}>{param.row.standardOriginal} EUR</Typography>}
                {param.row.name === "Felhasználó modul" &&
                    <Typography>{param.row.technicalUserCountStandard}</Typography>}
                {param.row.name === "Ár" && <Typography>{param.value} EUR</Typography>}
            </div>
        },
        {
            field: 'pro',
            renderHeader: (params) => (
                <div>
                    <span>{translate("purchase.professional")}</span>
                    <Checkbox checked={selectedPackage.pro} onChange={(e) => {
                        handleCheckboxChange(e, 'pro')
                    }}/>
                </div>
            ),
            headerAlign: 'center',
            width: 170,
            sortable: false,
            renderCell: (param) => <div style={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%"
            }}>
                {typeof param.value === "boolean" ? (param.value ?
                    <DoneIcon color="primary"/> : <ClearIcon opacity={0.2}/>) : param.row.name === "Ár" &&
                    <Typography sx={{textDecoration: 'line-through'}}>{param.row.proOriginal} EUR</Typography>}
                {param.row.name === "Felhasználó modul" && <Typography>{param.row.technicalUserCountPro}</Typography>}
                {param.row.name === "Ár" && <Typography>{param.value} EUR</Typography>}
            </div>
        },
        {
            field: 'proPlus',
            renderHeader: (params) => (
                <div>
                    <span>{translate("purchase.proPlus")}</span>
                    <Checkbox checked={selectedPackage.proPlus} onChange={(e) => {
                        handleCheckboxChange(e, 'proPlus')
                    }}/>
                </div>
            ),
            headerAlign: 'center',
            width: 170,
            sortable: false,
            renderCell: (param) => <div style={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%"
            }}>
                {typeof param.value === "boolean" ? (param.value ?
                    <DoneIcon color="primary"/> : <ClearIcon opacity={0.2}/>) : param.row.name === "Ár" &&
                    <Typography sx={{textDecoration: 'line-through'}}>{param.row.proPlusOriginal} EUR</Typography>}
                {param.row.name === "Felhasználó modul" && <Typography>unlimited</Typography>}
                {param.row.name === "Ár" && <Typography>{param.value} EUR</Typography>}
            </div>
        },
        // {
        //     ...GRID_CHECKBOX_SELECTION_COL_DEF,
        //     renderHeader: (params) => (
        //         <div style={{ display: 'flex', alignItems: 'center' }}>
        //             <span>{translate("purchase.unique")}</span>
        //             {GRID_CHECKBOX_SELECTION_COL_DEF.renderHeader(params)}
        //         </div>
        //     ),
        //     width: 150
        // }
    ]

    return (
        <>
        <Title title={translate("purchase.title")}/>
            {!loading &&
        companies.length === 0 ?
            <CardWithNoCompany/>
            :
            <Box sx={{width: '1040px'}}>
                <Autocomplete
                    value={selectedCompany}
                    renderInput={(params) => <TextField {...params} variant="standard"
                                                        label={translate("purchase.company")}/>}
                    options={companies}
                    getOptionLabel={(option) => option.name || ""}
                    onChange={(e, value) => {
                        if (value) {
                            localStorage.setItem(`${Resource}PosSetId`, value.id)
                        }
                        setSelectedCompany(value)
                    }}
                    sx={{width: '300px', marginTop: '10px'}}
                />
                {paymentUrl ?
                    <Card sx={{width: 450, marginTop: "40px"}}>
                        <CardContent>
                            <Typography sx={{fontSize: 18}} color="text.secondary" gutterBottom>
                                {translate("purchase.purchaseInProgress")}
                            </Typography>
                            <br/>
                            <Typography sx={{fontSize: 18}} variant="body2">
                                {translate(`purchase.continueOnSimple`)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button color="primary"
                                    onClick={() => redirect(paymentUrl)}>{translate("purchase.continueOnSimpleButton")}</Button>
                        </CardActions>
                    </Card>
                    :
                    <Box>
                        {Resource === "license" && card?.cardId && card?.cardMask !== null && card?.validUntil !==null &&
                            <Box sx={{marginY: '20px'}}>
                                <SaveBankCard card={card}/>
                            </Box>
                        }
                        <Box sx={{display: "flex", alignItems: "center", marginY: '20px'}}>
                            <Typography>{translate("purchase.license")}</Typography>
                            <TextField
                                sx={{marginLeft: '20px', width: '100px'}}
                                variant={"standard"}
                                type={"number"}
                                value={quantity}
                                onChange={(e) => {
                                    if (selectedPackage.lite) {
                                        setSum(0)
                                        setQuantity(1)
                                        notify("purchase.liteCount", {type: "warning"})
                                        return
                                    }
                                    if ((/^\d*$/.test(e.target.value) && e.target.value >= 1) || e.target.value === "") {
                                        setQuantity(e.target.value)
                                        setSum((rows[0][Object.keys(selectedPackage).find(key => selectedPackage[key])] * e.target.value) + (selectedSupport.netPrice * e.target.value))
                                    }
                                }}
                            />

                        </Box>
                        <Typography sx={{marginBottom: '20px'}}>{translate("purchase.details")}</Typography>
                        <ThemeProvider theme={muiDatagridTheme}>
                            <DataGrid
                                columns={columns}
                                rows={rows}
                                disableSelectionOnClick
                                disableColumnMenu
                                hideFooter
                                autoHeight
                                disableRowSelectionOnClick
                                getRowHeight={(params) => 'auto'}
                            />
                        </ThemeProvider>

                        {Resource === "license" ?
                            <div>
                                {!selectedPackage.lite &&
                                    <Box sx={{
                                        display: "flex",
                                        width: "40%",
                                        justifyContent: "space-between",
                                        marginY: "10px"
                                    }}>
                                        <Autocomplete
                                            value={selectedSupport}
                                            renderInput={(params) => <TextField {...params} variant="standard"
                                                                                label={translate("purchase.support")}/>}
                                            options={support}
                                            getOptionLabel={(option) => `${option.name} - ${option.netPrice} EUR` || ""}
                                            onChange={(e, value) => {
                                                setSelectedSupport(value)
                                                setSum((rows[0][Object.keys(selectedPackage).find(key => selectedPackage[key])] * quantity) + (value.netPrice * quantity))
                                            }}
                                            sx={{width: '300px'}}
                                            disableClearable
                                        />
                                        <Tooltip title={
                                            <span style={{fontSize: "14px"}}>
                            {translate("purchase.basicSupportTooltip")}
                                                <br/> <br/> {translate("purchase.dailySupportTooltip")}
                                                <br/> <br/> {translate("purchase.fullSupportTooltip")}
                            </span>
                                        }
                                                 placement="right" arrow
                                        >

                                            <IconButton>
                                                <HelpIcon sx={{color: "#ff71bc"}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                }
                                <Box sx={{display: "flex", marginY: '20px'}}>
                                    <Typography>{translate("purchase.sum")}</Typography>
                                    <Typography>{sum} €</Typography>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    marginY: '10px',
                                    width: '1040px',
                                    justifyContent: 'space-between'
                                }}>
                                    <Button sx={{color: 'white', marginY: '10px'}}
                                            disabled={!(!!selectedCompany) || (voucher.length > 0 && !validVoucher)} variant="contained"
                                            color="primary"
                                            onClick={continueToPay}>
                                        {translate("purchase.continue")}
                                    </Button>
                                    {!selectedPackage.lite && !isFirstPurchase &&
                                        <Resignation sendPurchase={sendPurchase}/>}
                                    <TextField variant={"standard"} value={voucher} label={translate("purchase.voucher")} onChange={(e)=>{setVoucher(e.target.value)}} />
                                    <Button sx={{color: 'white', marginY: '10px'}}
                                            variant="contained"
                                            color="primary"
                                            onClick={voucherValidation}
                                    >
                                            {translate("purchase.validateVoucher")}
                                </Button>
                                </Box>
                            </div>
                            :
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Autocomplete
                                    value={selectedSupport}
                                    renderInput={(params) => <TextField {...params} variant="standard"
                                                                        label={translate("purchase.support")}/>}
                                    options={support}
                                    getOptionLabel={(option) => `${option.name} - ${option.netPrice} EUR` || ""}
                                    onChange={(e, value) => {
                                        setSelectedSupport(value)
                                        setSum((rows[0][Object.keys(selectedPackage).find(key => selectedPackage[key])] * quantity) + (value.netPrice * quantity))
                                    }}
                                    sx={{width: '300px', marginY: '10px'}}
                                    disableClearable
                                />
                                <Box sx={{display: "flex", marginY: '20px'}}>
                                    <Typography>{translate("purchase.sum")}</Typography>
                                    <Typography>{sum} €</Typography>
                                </Box>
                                <ThemeProvider theme={muiDatagridTheme}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
                                        <DateTimePicker
                                            value={selectedDate}
                                            label={translate(`purchase.date`)}
                                            renderInput={(params) => <TextField {...params} />}
                                            minDateTime={dayjs()}
                                            disabled={selectedPackage.lite}
                                            views={['year', 'month', 'day']}
                                            onChange={(newValue) => {
                                                setSelectedDate(newValue)
                                            }}
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>
                                <Button sx={{color: 'white', marginY: '10px'}} variant="contained" color="primary"
                                        disabled={!hasUpdate}
                                        onClick={sendPurchase}>{translate("purchase.save")}</Button>
                            </div>
                        }
                    </Box>
                }
            </Box>
            }
        </>
    )
}

export default Purchase