import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import {
    List,
    TextField,
    DatagridConfigurable,
    usePermissions,
    Pagination,
    SearchInput,
    AutocompleteArrayInput,
    SelectColumnsButton,
    CreateButton,
    ExportButton,
    TopToolbar, FilterButton
} from "react-admin";
import {EmptyPage} from "../../components/EmtpyPage";
import {PinkActions} from "../../components/PinkActions";
import {PhoneField} from "../../components/PhoneField";
import React from "react";



export const CompanyList = () => {
    const { permissions } = usePermissions()
    const hasEdit = getValidPrivilegesIds("COMPANY", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const postFilters =  [
        <SearchInput sx={{'& input': {
                height: '31px',
            },width: "300px"}} source="companySearch" alwaysOn={true} resettable={true}/>
    ]
    return(
        <List pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 500]} />} filters={postFilters} actions={<TopToolbar>
            <SelectColumnsButton />
                <CreateButton/>
            <FilterButton/>
            <ExportButton/>
        </TopToolbar>} title={"company.title"} empty={<EmptyPage text={"company.empty"} createEnabled={true} />}>
            <DatagridConfigurable size="small"  omit={['id', 'euTaxNumber', 'companyRegistrationNumber', 'contactPhone']} rowClick={hasEdit} bulkActionButtons={false} >
                <TextField source="id" label={'company.id'} />
                <TextField source="name" label={'company.name'} />
                <TextField source="zipCode" label={'company.zipCode'} />
                <TextField source="state" label={'company.state'} />
                <TextField source="settlement" label={'company.settlement'} />
                <TextField source="address" label={'company.address'} />
                <TextField source="taxNumber" label={'company.taxNumber'} />
                <TextField source="groupTaxNumber" label={'company.groupTaxNumber'} />
                <TextField source="euTaxNumber" label={'company.euTaxNumber'} />
                <TextField source="companyRegistrationNumber" label={'company.companyRegistrationNumber'} />
                <TextField source="contactName" label={'company.contactName'} />
                <TextField source="contactEmail" label={'company.contactEmail'} />
                <PhoneField source={"contactPhone"} label={"company.contactPhone"} />
            </DatagridConfigurable>
        </List>
    )
}