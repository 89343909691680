import {ArrayInput, Create, required, SimpleForm, SimpleFormIterator, TextInput} from "react-admin";
import React from "react";

const PrinterCreate = () => {
    const transform = (data) => {
        data.businessUnitId = localStorage.getItem("v1/order/printerPosSetId")
        return data
    }
    return (
        <Create transform={transform} redirect={'list'}>
            <SimpleForm>
                <ArrayInput name={"printers"} source={"printers"} label={'Nyomtatók'}>
                    <SimpleFormIterator>
                        <TextInput source={"name"} label={"printer.name"} validate={required()} />
                        <TextInput source={"serialNumber"} validate={required()} label={"printer.serialNumber"} />
                    </SimpleFormIterator>
                    </ArrayInput>
            </SimpleForm>
        </Create>
    );
}

export default PrinterCreate;