import React, {useEffect} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    FormDataConsumer,
    minValue, RadioButtonGroupInput,
    ReferenceInput,
    regex,
    required,
    SelectInput,
    SimpleFormIterator,
    TextInput,
    useDataProvider,
    useLocaleState,
    useRecordContext,
    useTranslate
} from "react-admin";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {AutocompleteSimpleInput} from "../../components/AutocompleteSimpleImput";
import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import {useFormContext} from "react-hook-form";
import {DataGrid} from '@mui/x-data-grid';
import {ProductVatData} from "./ProductVatData";
import removeAccents from "../../components/removeAccents";
import Ingredients from "../warehouse/ingredients/Ingredients";
import {huHU} from '@mui/material/locale'
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";

const ProductDetails = ({permissions}) => {
    const {setValue, getValues} = useFormContext();
    const record = useRecordContext();
    const defaultTags = record !== undefined && record.tags.length > 0 ? record.tags.map(value => value.name) : []
    const translate = useTranslate();
    const [tag, setTag] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [selectedTag, setSelectedTag] = React.useState([]);
    const dataProvider = useDataProvider()
    const [open, setOpen] = React.useState(false);
    const locale = useLocaleState();
    const [categories, setCategories] = React.useState({
        mainCategory: {name: "", options:[]},
        subCategory: {name:"", options:[]},
        subSubCategory: {name:"", options:[]}
    });

    //Ez kell ide, vagy fefülírja a táblázat, amíg nincs módosítva. Ezt át lehetne rakni backend részre, hogy tag vagy név tömbként vagy név id listaként megy fel
    setValue('tags', selectedTag)
    setValue('posSetId', parseInt(localStorage.getItem("productPosSetId")))

    const IngredientsBoxStyle = {
        position: 'relative',
        marginLeft: '16rem',
        top: '-9.5rem',
    }

    const refundBottle = [
        {id: 'NONE', name: 'Nincs'},
        {id: 'ONE_WAY', name: 'Egyutas palack (50 Ft)'},
        {id: 'MULTI_WAY', name: 'Többutas palack'},
    ]

    const handleDisableRemove = (record) => {
        return record.id
    };

    const limitTo2Decimal = (e) => {
        e.target.value = e.target.value.replace(".", ",")
    }

    const handleChange = (event, newValue) => {
        setValue('subCategoryId', null)
    };

    const handleTag = (event, newValue) => {
        //setValue('tags', newValue) //Ez majd akkor kell, ha átkerül be-re a fenti
        setSelectedTag(newValue)
    }

    const handleInputBlur = (event) => {
        const inputValue = event.target.value.trim();

        if (inputValue && !selectedTag.includes(inputValue)) {
            setSelectedTag([...selectedTag, inputValue]);
        }
    };

    useEffect(() => {
        if (defaultTags !== undefined) {
            //setValue('tags', defaultTags) //Ez majd akkor kell, ha átkerül be-re a fenti
            setSelectedTag(defaultTags)
        }
    }, [record]);

    useEffect(
        () => {
            if (record){
                const category = record.customCategory?.split('/.');
                if (category) {
                    setCategories({
                        mainCategory: {name: category[0] ? category[0] : "", options: []},
                        subCategory: {name: category[1] ? category[1] : "", options: []},
                        subSubCategory: {name: category[2] ? category[2] : "", options: []}
                    })
                }
            }
        }
    , [])

    useEffect(() => {
        if (!tag.loading && !tag.loaded) {
            setTag({...tag, loading: true});
            dataProvider.get(`product/tag`, {posSetId: localStorage.getItem("productPosSetId")})
                .then(value => {
                    //setTag({loading: false, loaded: true, data: value.data, error: undefined});
                    setTag({
                        loading: false,
                        loaded: true,
                        data: (value.data.map(value => value.name).sort((a, b) => removeAccents(a) > removeAccents(b) ? 1 : -1)),
                        error: undefined
                    });
                })
                .catch(reason => {
                    setTag({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [tag.loading, tag.loaded]);

    useEffect(
        () => {
            dataProvider.get('product/custom-category', {posSetId: localStorage.getItem("productPosSetId")})
                .then(({data}) => {
                   setCategories(prevCategories => ({
                          ...prevCategories,
                            mainCategory: {
                                ...prevCategories.mainCategory,
                                options: data
                            }
                   }))
                })
                .catch(reason => {
                    console.log(reason)
                })
        }
    , [])

    useEffect(
        () => {
            dataProvider.get('product/custom-category', {posSetId: localStorage.getItem("productPosSetId"), search: `${categories.mainCategory.name}/.`})
                .then(({data}) => {
                    setCategories(prevCategories => ({
                        ...prevCategories,
                        subCategory: {
                            ...prevCategories.subCategory,
                            options: data
                        }
                    }))
                })
                .catch(reason => {
                    console.log(reason)
                })
        }
    , [categories.mainCategory])

    useEffect(
        () => {
            if (categories.subCategory.name === "") return;
            dataProvider.get('product/custom-category', {posSetId: localStorage.getItem("productPosSetId"), search: `${categories.mainCategory.name}/.${categories.subCategory.name}/.`})
                .then(({data}) => {
                    setCategories(prevCategories => ({
                        ...prevCategories,
                        subSubCategory: {
                            ...prevCategories.subSubCategory,
                            options: data
                        }
                    }))
                })
                .catch(reason => {
                    console.log(reason)
                })
        }
        , [categories.mainCategory, categories.subCategory])

    useEffect(
        () => {
            const { mainCategory, subCategory, subSubCategory } = categories;
            const categoryParts = [mainCategory?.name, subCategory?.name, subSubCategory?.name].filter(part => part);
            const customCategory = categoryParts.join('/.');
            setValue('customCategory', customCategory === '' ? null : customCategory);
        }
    , [categories])

    return (
        <>
            <TextInput fullWidth source="name" label={"products.name"} validate={required()}
                       inputProps={{maxLength: 255}}/>
            <AutocompleteSimpleInput source={"mainCategoryId"} label={"products.mainCategory"}
                                     multiple={false}
                                     reference={'product/mainCategory'}
                                     sortField={"name"}
                                     optionText={"name"}
                                     validate={required()} onChange={handleChange}/>
            <FormDataConsumer>
                {({formData}) => (
                    formData.mainCategoryId &&
                    <AutocompleteSimpleInput source={"subCategoryId"} label={"products.subCategory"}
                                             multiple={false}
                                             reference={'product/subCategory'}
                                             sortField={"name"}
                                             optionText={"name"} filter={{mainCategoryId: formData.mainCategoryId}}
                                             validate={required()}/>
                )}
            </FormDataConsumer>

            <Autocomplete
                renderInput={
                    (params) => <TextField onChange={(e) => {
                        setCategories(prevCategories => ({
                            mainCategory: {
                                ...prevCategories.mainCategory,
                                name: e.target.value
                            },
                            subCategory: {name:"", options:[]},
                            subSubCategory: {name:"", options:[]}
                        }));
                    }} {...params} label={translate("products.mainCat")}/>
                }
                getOptionLabel={(option) => option.name || ""}
                options={categories.mainCategory.options}
                fullWidth={true}
                value={categories.mainCategory}
                onChange={(event, newValue) => {
                    setCategories(prevCategories => ({
                        mainCategory: {
                            ...prevCategories.mainCategory,
                            name: newValue ? newValue.name : ""
                        },
                        subCategory: {name:"", options:[]},
                        subSubCategory: {name:"", options:[]}
                    }));
                }}
                sx={{marginBottom: 2}}
            />
            {categories.mainCategory.name &&
            <Autocomplete
                renderInput={
                    (params) => <TextField onChange={(e) => {
                        setCategories(prevCategories => ({
                            ...prevCategories,
                            subCategory: {
                                ...prevCategories.subCategory,
                                name: e.target.value
                            }
                        }));
                    }} {...params} label={translate("products.subCat")}/>
                }
                getOptionLabel={(option) => option.name || ""}
                options={categories.subCategory.options}
                fullWidth={true}
                value={categories.subCategory}
                onChange={(event, newValue) => {
                    setCategories(prevCategories => ({
                        ...prevCategories,
                        subCategory: {
                            ...prevCategories.subCategory,
                            name: newValue ? newValue.name : ""
                        }
                    }));
                }}
                sx={{marginBottom: 2}}
            />
            }
            {categories.subCategory.name &&
            <Autocomplete
                renderInput={
                    (params) => <TextField onChange={(e) => {
                        setCategories(prevCategories => ({
                            ...prevCategories,
                            subSubCategory: {
                                ...prevCategories.subSubCategory,
                                name: e.target.value
                            }
                        }));
                    }} {...params} label={translate("products.subSubCat")}/>
                }
                getOptionLabel={(option) => option.name || ""}
                options={categories.subSubCategory.options}
                fullWidth={true}
                value={categories.subSubCategory}
                onChange={(event, newValue) => {
                    setCategories(prevCategories => ({
                        ...prevCategories,
                        subSubCategory: {
                            ...prevCategories.subSubCategory,
                            name: newValue ? newValue.name : ""
                        }
                    }));
                }}
                sx={{marginBottom: 2}}
            />
            }
            <Autocomplete
                multiple
                id="tags"
                getOptionLabel={(data) => data?.toString()}
                options={tag.data} //{ tag.data.map(value => value.name || value)}
                value={selectedTag}
                freeSolo
                fullWidth
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                onChange={handleTag}
                onBlur={handleInputBlur}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{...params.inputProps, maxLength: 64}}
                        variant="filled"
                        label={translate('products.tags')}
                    />
                )}
                sx={{marginBottom: 2}}
            />

            <Stack direction="row" spacing={2}>
                <ReferenceInput source={"vatId"} reference={'product/vatGroup'}>
                    <SelectInput optionText={"name"} label={"products.vatGroup"} defaultValue={12}
                                 validate={required()}/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({formData}) => formData.vatId === 10 &&
                        <BooleanInput label={"products.isTakeawayAllowed"} source="isTakeawayAllowed"/>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData}) => formData.isTakeawayAllowed && formData.vatId === 10 &&
                        <ReferenceInput source={"takeawayVatId"} reference={'product/vatGroup'}>
                            <SelectInput optionText={"name"} label={"products.takeawayVatGroup"} defaultValue={12}
                                         validate={required()}/>
                        </ReferenceInput>
                    }
                </FormDataConsumer>
            </Stack>
            <FormDataConsumer>
                {({formData}) => formData.vatId === 10 &&
                    <Typography color={"#ff71bc"}>
                        {translate("products.fivePercentText")}
                    </Typography>
                }
            </FormDataConsumer>
            <Button type={"button"} onClick={() => setOpen(!open)}>
                {open ? translate("products.hideVatHint") : translate("products.showVatHint")}
            </Button>

            {open && <Box
                sx={{
                    height: 300,
                    width: 900,
                    '& .vatFive': {
                        backgroundColor: 'rgba(153, 227, 121)'
                    },
                    '& .vatEighteen': {
                        backgroundColor: 'rgba(255, 255, 0)'
                    },
                }}
            >
                {open && locale[0] === "hu" &&
                    <DataGrid localeText={huHU.components.MuiDataGrid}
                              columns={[
                                  {field: 'name', headerName: 'Név', width: 500},
                                  {field: 'localVat', headerName: 'Helyi ÁFA', width: 120, sortable: false},
                                  {field: 'takeawayVat', headerName: 'Elviteles ÁFA', width: 120, sortable: false},
                                  {field: 'deliveryVat', headerName: 'Kiszállítás ÁFA', width: 120, sortable: false},
                              ]}
                              rows={ProductVatData}
                              disableRowSelectionOnClick
                              hideFooter
                              getCellClassName={(params) => {
                                  if (params.value === "5") {
                                      return "vatFive";
                                  } else if (params.value === "18" || params.value === "18*") {
                                      return "vatEighteen";
                                  } else return ""
                              }}
                    />}
                {open && locale[0] === "en" &&
                    <DataGrid localeText={huHU.components.MuiDataGrid}
                              columns={[
                                  {field: 'name', headerName: 'Name', width: 500},
                                  {field: 'localVat', headerName: 'Local Vat', width: 120, sortable: false},
                                  {field: 'takeawayVat', headerName: 'Takeaway Vat', width: 120, sortable: false},
                                  {field: 'deliveryVat', headerName: 'Delivery Vat', width: 120, sortable: false},
                              ]}
                              rows={ProductVatData}
                              disableRowSelectionOnClick
                              hideFooter
                              getCellClassName={(params) => {
                                  if (params.value === "5") {
                                      return "vatFive";
                                  } else if (params.value === "18" || params.value === "18*") {
                                      return "vatEighteen";
                                  } else return ""
                              }}
                    />}
            </Box>}
            {open && locale[0] === "hu" &&
                <Typography color={"#ff71bc"}>*csak akkor, ha a cég tevékenységi köre között szerepel a
                    "cukrászati"</Typography>}
            <ArrayInput source={"units"} label={"products.productUnits"} name={'units'}>
                <SimpleFormIterator disableReordering={true}>
                    disableClear={true} /*disableRemove={handleDisableRemove}*/>
                    <ReferenceInput source={"unit"} reference={'product/unit'}>
                        <AutocompleteInput optionText={"name"} label={"products.unit"} validate={required()}/>
                    </ReferenceInput>
                    <TextInput source={"quantity"} label={"products.quantity"} type={"number"}
                               validate={[required(), minValue(0.01, "products.greaterThanZero"), regex(/^([1-9]\d{0,8})|0(?:\.\d{1,2})?$/, "products.wrongQuantityFormat")]}
                    />
                    <TextInput source={"defaultPrice"} label={"products.defaultPrice"} type={"number"}
                               validate={[required(), regex(/^(-?\d{1,9})$/, "products.wrongPriceFormat")]}
                    />
                    <FormDataConsumer>
                        {({ getSource, scopedFormData}) => {
                            return(
                            <BooleanInput
                                source={getSource("bulk")}
                                label={"products.bulkItem"}
                                onChange={(e) => {
                                    if(e.target.checked === true){
                                        scopedFormData.depositFee.hasDepositFee = "NONE"
                                    }
                                }}
                            />
                        )}}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({getSource, scopedFormData, formData}) => {
                            return (
                                <div style={IngredientsBoxStyle}>
                                    <Ingredients scopedFormData={scopedFormData} permissions={permissions}/>
                                </div>
                            )

                        }}
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ scopedFormData }) => (
                            !scopedFormData.bulk && (
                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <Typography variant={"body1"} sx={{
                                        fontSize: '12px',
                                        color: '#666666'
                                    }}>{translate("products.refundBottle")}</Typography>
                                    <Tooltip title={
                                        <span style={{fontSize: "14px"}}>
                    {translate("products.depositFeeHint")}
                    </span>
                                    }
                                             placement="right" arrow
                                    >
                                        <IconButton>
                                            <HelpIcon sx={{color: "#ff71bc"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )
                        )}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ getSource,scopedFormData }) => {
                            if (!scopedFormData.depositFee) {
                                scopedFormData.depositFee = {
                                    hasDepositFee: 'NONE',
                                    depositFeeCount: 1,
                                    depositFeePrice: null
                                }
                            }
                            if (scopedFormData.depositFee.hasDepositFee === "ONE_WAY"){
                                scopedFormData.depositFee.depositFeeCount = 1
                            }
                            return(
                                <Box sx={{display: !scopedFormData.bulk ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'space-between', alignItems: scopedFormData.depositFee.hasDepositFee === "ONE_WAY" ? "center" : "end"}}>
                                <RadioButtonGroupInput key={scopedFormData.depositFee.hasDepositFee} source={getSource("depositFee.hasDepositFee")} optionValue="id" choices={refundBottle} label={false}
                                                       defaultValue={"NONE"} row={false}/>
                                    {scopedFormData.depositFee.hasDepositFee === "MULTI_WAY" && <TextInput sx={{width: 70}} inputProps={{min: 1}} label={"products.depositFeePrice"} source={getSource("depositFee.depositFeePrice")} defaultValue={1} type={"number"} validate={[required(), regex(/^[1-9]\d*$/, "products.wrongPriceFormat")]} />}
                                </Box>
                            )}}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </>
    )
}

export default ProductDetails