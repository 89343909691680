import {
    BooleanField,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    List,
    SimpleForm,
    TextField, usePermissions,
    useRecordContext,
    useTranslate,
    FunctionField,
    Button,
    useDataProvider
} from "react-admin";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    DialogTitle,
    Dialog
} from '@mui/material';
import { getValidPrivilegesIds } from "../../components/authentication/PermissionHandler";
import DialogContent from "@mui/material/DialogContent"
import { EmptyPage } from "../../components/EmtpyPage";
import React, { useEffect, useState } from "react";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import { PinkPagination } from "../../components/PinkPagination";



export const DeviceList = () => {
    const { permissions } = usePermissions()
    const hasEdit = getValidPrivilegesIds("DEVICE", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const hasDelete = getValidPrivilegesIds("DEVICE", "DELETE").some(r => permissions.includes(r)) ? "edit" : undefined
    const [selectedPosSetId, setSelectedPosSetId] = useState(0)
    const [open, setOpen] = useState(false)
    const [hierarchy, setHierarchy] = useState({})
    const dataProvider = useDataProvider()
    const translate = useTranslate()

    useEffect(() => {
        if (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) {
            localStorage.setItem("devicePosSetId", selectedPosSetId)
        }
    }, [selectedPosSetId])

    const DeleteField = (props) => {
        const record = useRecordContext();
        const translate = useTranslate()
        if (hasDelete && !record.isShiftOpen) {
            return (
                <DeleteWithConfirmButton confirmTitle={"device.detachAreYouSure"}
                    confirmContent={`${record.deviceId} ${record.deviceName}`}
                    mutationMode={"pessimistic"} label={"device.remove"} />
            )
        } else {
            if (hasDelete) {
                return (
                    <Typography>{translate("device.cantRemove")}</Typography>
                )
            }
        }

    }

    const handleButtonClick = (event, posId) => {
        event.stopPropagation()
        setOpen(true)
        dataProvider.get("device/hierarchy", { posId: posId })
            .then(value => {
                setHierarchy(value.data)
            })
            .catch(reason => console.log(reason))
    }

    return (
        <div>
            <SimpleForm toolbar={false} sx={{ width: '300px' }}>
                <SelectCompanyWithBusinessUnitInput getResource={"device"} setPosSet={setSelectedPosSetId} />
            </SimpleForm>
            {(selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) &&
                <List title={"device.title"} empty={<EmptyPage text={"device.empty"} />}
                    pagination={<PinkPagination trigger={selectedPosSetId} />}
                    filterDefaultValues={{ posSetId: selectedPosSetId }}
                >
                    <Datagrid bulkActionButtons={false} rowClick={hasEdit}>
                        <TextField source="id" label={'device.posId'} />
                        <TextField source="posName" label={'device.posName'} />
                        <TextField source="deviceId" label={'device.deviceId'} />
                        <TextField source="deviceName" label={'device.deviceName'} />
                        <DateField source="pricelistCheckTime" label={'device.priceListCheckTime'} showTime
                            locales="hu-HU" />
                        <DateField source="configCheckTime" label={'device.configCheckTime'} showTime locales="hu-HU" />
                        <DateField source="lastCommunication" label={'device.lastCommunication'} showTime
                            locales="hu-HU" />
                        <BooleanField source={"isShiftOpen"} label={'device.shiftOpen'} />
                        <TextField source="mobileVersion" label={'device.mobileVersion'} />
                        <DeleteField label={'device.removeShort'} />
                        <FunctionField render={record => {
                            return (
                                <div>
                                    <Button label={"POS hierarchia"} variant={"outlined"} onClick={event => handleButtonClick(event, record.id)} />
                                </div>)
                        }} />
                    </Datagrid>
                </List>}
            <div>
                <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}
                    sx={{ '& .MuiDialog-paper': { width: '50em', maxWidth: '100%' } }}
                >
                    <DialogTitle>POS hierachia</DialogTitle>
                    <DialogContent sx={{ display: 'block', textAlign: 'center' }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{translate('groups.pos')}</TableCell>
                                        {hierarchy.barName && <TableCell>{translate('groups.bar')}</TableCell>}
                                        {hierarchy.areaName && <TableCell>{translate('groups.area')}</TableCell>}
                                        {hierarchy.buName && <TableCell>{translate('groups.businessUnit')}</TableCell>}
                                        <TableCell>{translate('groups.company')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={hierarchy.posName}>
                                        <TableCell> {hierarchy.posName}</TableCell>
                                        {hierarchy.barName && <TableCell>{hierarchy.barName}</TableCell>}
                                        {hierarchy.areaName && <TableCell>{hierarchy.areaName}</TableCell>}
                                        {hierarchy.buName && <TableCell>{hierarchy.buName}</TableCell>}
                                        <TableCell>{hierarchy.companyName}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}