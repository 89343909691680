import {useListContext} from "react-admin";
import {useEffect} from "react";

const BulkActionButtonsToPrinter = ({selectedIds, setSelectedIds}) => {

    const listContext = useListContext();

    useEffect(() => {
        listContext.onSelect(listContext.data.filter(it => it.selected).map(it => it.id));
    }, []);

    useEffect(() => {
        setSelectedIds(selectedIds);
        listContext.onSelect(selectedIds);
    }, [selectedIds]);
    return null
}

export default BulkActionButtonsToPrinter;