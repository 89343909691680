import {
    Button,
    CreateButton,
    DatagridConfigurable,
    FunctionField,
    List,
    TextField,
    TopToolbar, useNotify,
    useRefresh
} from "react-admin";
import React, {useEffect, useState} from "react";
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import {EmptyPage} from "../../components/EmtpyPage";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import {PinkPagination} from "../../components/PinkPagination";
import dataProvider from "../../components/dataProvider";
import Dialog from "@mui/material/Dialog";
import {DialogTitle} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

const PrinterList = ({permissions}) => {
    const hasEdit = getValidPrivilegesIds("DEVICE", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const [selectedPosSetId, setSelectedPosSetId] = useState(0)
    const refresh = useRefresh()
    const [open, setOpen] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const notify = useNotify()

    useEffect(() => {
        if (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) {
            localStorage.setItem("v1/order/printerPosSetId", selectedPosSetId)
        }
    }, [selectedPosSetId])

    const handlePrinterDelete = (id) => {
        dataProvider.delete("v1/order/printer", {id: id}).then(
            response => {
                notify("Nyomtató sikeressen törölve", {type: 'success', autoHideDuration: 3000})
                refresh()
            }
        )
            .catch(error => {
                console.log(error)
                notify("Hiba történt a törlés során", {type: 'error', autoHideDuration: 3000})
            })
        setDeleteId(null)
        setOpen(false)
    }

    const handlePrinterDeleteCheck = (event, id) => {
        event.stopPropagation()
        setDeleteId(id)
        dataProvider.get("v1/order/printer/products", {orderPrinterId: id}).then(
            response => {
                if (response.data.content.some(item => item.selected === true)) {
                    setOpen(true)
                } else {
                    handlePrinterDelete(id)
                }
            })
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"FIGYELMEZTETÉS!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        A nyomtatóhoz termékek vannak hozzárendelve. Biztosan törölni szeretné a nyomtatót?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handlePrinterDelete(deleteId)
                    }}>Törlés mindenképp</Button>
                    <Button onClick={handleClose} autoFocus>Mégse</Button>
                </DialogActions>
            </Dialog>
            <SelectCompanyWithBusinessUnitInput getResource={"v1/order/printer"} setPosSet={setSelectedPosSetId}
                                                companySelectDisabled={true}/>
            {(selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) &&
                <List actions={<TopToolbar>
                    <CreateButton/>
                </TopToolbar>}
                      pagination={<PinkPagination trigger={selectedPosSetId}/>}
                      filter={{businessUnitId: selectedPosSetId}}
                      title={"printer.title"} empty={<EmptyPage text={"company.empty"} createEnabled={hasEdit}/>}>
                    <DatagridConfigurable size="small" rowClick={hasEdit} bulkActionButtons={false}>
                        <TextField source="id" label={'printer.id'}/>
                        <TextField source="name" label={'printer.name'}/>
                        <TextField source="serialNumber" label={'printer.serialNumber'}/>
                        <FunctionField render={record => {
                            return <Button onClick={(event) => {
                                handlePrinterDeleteCheck(event, record.id)
                            }} variant={"contained"}>{"Nyomtató törlése"}</Button>
                        }} label={'printer.delete'}/>
                    </DatagridConfigurable>
                </List>}
        </>
    )
}

export default PrinterList