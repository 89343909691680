// in src/Menu.js
import * as React from 'react';
import {Menu, useTranslate, usePermissions, useSidebarState} from 'react-admin';
import {useEffect, useState} from "react";
import {MenuItem} from "@mui/material";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {getValidPrivilegesIds} from "./authentication/PermissionHandler";
import dataProvider from "./dataProvider";
import {SidebarContext} from "./SidebarContext";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DescriptionIcon from '@mui/icons-material/Description';
import FactoryIcon from '@mui/icons-material/Factory';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Tooltip from '@mui/material/Tooltip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {Link} from "react-router-dom";

function GetPermission(resource, method, corpAdmin) {
    const permissions = usePermissions().permissions
    return getValidPrivilegesIds(resource, method, corpAdmin || false).some(r => permissions.includes(r))
}

const AdministrationRender = () => {
    const {isSidebarOpen} = React.useContext(SidebarContext)
    const [openAdministration, setOpenAdministration] = useState(false)
    const translate = useTranslate();
    return <div key={"administrationMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
        <Tooltip title={isSidebarOpen ? "" : translate('menu.administration')} placement={"right"}>
            <MenuItem sx={{paddingLeft: isSidebarOpen ? '16px' : '0px'}}
                      onClick={() => setOpenAdministration(!openAdministration)}>
                {openAdministration ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}
                {isSidebarOpen ? translate('menu.administration') : <SupervisorAccountIcon/>}
            </MenuItem>
        </Tooltip>
        {openAdministration &&
            <div key={"administrationMenuItems"} style={{paddingLeft: '10px'}}>
                <Menu.ResourceItem name="company"/>
                <Menu.ResourceItem name="businessUnit"/>
                <Menu.ResourceItem name={"ntakIntegration"}/>
            </div>
        }
    </div>
}

const PriceListMenuItems = () => {
    return (
        <div key={"priceListMenuItems"} style={{paddingLeft: '10px'}}>
            {GetPermission("PRICELIST", "LIST") && <Menu.ResourceItem name="priceList"/>}
            {GetPermission("PRICELIST", "CREATE") && <Menu.ResourceItem name="quickMenu"/>}
            {GetPermission("PRICELIST_TO_POSSET", "LIST") && <Menu.ResourceItem name="priceListToPosSet"/>}
            {GetPermission("POS_WITH_PRICELIST", "LIST") && <Menu.ResourceItem name="pos/pricelist"/>}
        </div>
    )
}

const WarehouseMenuItems = () => {
    return <div key={"warehouseMenuItems"} style={{paddingLeft: '10px'}}>
        <Menu.ResourceItem name="warehouse-product"/>
        <Menu.ResourceItem name="warehouse"/>
        <Menu.ResourceItem name="supplier"/>
        <Menu.ResourceItem name="warehouse-operation"/>
        <Menu.ResourceItem name="inventory"/>
        <Menu.ResourceItem name="stock-change-report/report"/>
        <Menu.ResourceItem name="stockpile"/>
    </div>
}

const PriceListRender = () => {
    const {isSidebarOpen} = React.useContext(SidebarContext)
    const [openPriceList, setOpenPriceList] = useState(false)
    const translate = useTranslate();
    const notEmpty = GetPermission("PRICELIST", "LIST") || GetPermission("PRICELIST", "CREATE") || GetPermission("PRICELIST_TO_POSSET", "LIST") || GetPermission("POS_WITH_PRICELIST", "LIST")
    const allSimplified = localStorage.getItem('allSimplified')
    return (
        allSimplified === 'true' ?
           notEmpty && <Menu.ResourceItem name="quickMenu"/>
            : (
                <div key={"priceListMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
                    {notEmpty &&
                        <Tooltip title={isSidebarOpen ? "" : translate('menu.priceList')} placement={"right"}>
                            <MenuItem sx={{paddingLeft: isSidebarOpen ? '16px' : '0px'}}
                                      onClick={() => setOpenPriceList(!openPriceList)}>
                                {openPriceList ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}
                                {isSidebarOpen ? translate('menu.priceList') : <DescriptionIcon/>}
                            </MenuItem>
                        </Tooltip>}
                    {openPriceList && <PriceListMenuItems/>}
                </div>
            )
    )
}

const WarehouseRender = () => {
    const {isSidebarOpen} = React.useContext(SidebarContext)
    const [openStorage, setOpenStorage] = useState(false)
    const translate = useTranslate();
    const notEmpty = GetPermission("WAREHOUSE", "LIST") || GetPermission("WAREHOUSE", "CREATE") || GetPermission("WAREHOUSE", "UPDATE") || GetPermission("WAREHOUSE", "DELETE")
    return <div key={"warehouseMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
        {notEmpty && <Tooltip title={isSidebarOpen ? "" : translate('menu.storage')} placement={"right"}>
            <MenuItem sx={{paddingLeft: isSidebarOpen ? '16px' : '0px'}} onClick={() => setOpenStorage(!openStorage)}>
                {openStorage ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}
                {isSidebarOpen ? translate('menu.storage') : <FactoryIcon/>}
            </MenuItem>
        </Tooltip>}
        {openStorage && <WarehouseMenuItems/>}
    </div>
}

const UserManualRender = () => {
    const [openUserManual, setOpenUserManual] = useState(false)
    const url = window.location.href.split('#')[0]
    const translate = useTranslate();
    return <div key={"userManualMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
        <MenuItem onClick={() => setOpenUserManual(!openUserManual)}>
            {openUserManual ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}
            {translate('menu.userManual')}
        </MenuItem>
        {openUserManual && <div key={"userManualMenuItems"} style={{paddingLeft: '10px'}}>
            <div style={{paddingLeft: '20px'}}>
                <div style={{display: 'flex', alignItems: 'end', gap: '15px', marginBottom: '5px'}}>
                    <PictureAsPdfIcon sx={{fontSize: '1.8rem'}}/>
                    <Link style={{color: '#ff71bc'}} to={url.concat('PinkPOS admin oktatási anyag.pdf')}
                          target="_blank">
                        PinkPos Admin
                    </Link>
                </div>
                <div style={{display: 'flex', alignItems: 'end', gap: '15px'}}>
                    <PictureAsPdfIcon sx={{fontSize: '1.8rem'}}/>
                    <Link style={{color: '#ff71bc'}} to={url.concat('PinkPOS alkalmazás oktatási anyag_v2.pdf')}
                          target="_blank">
                        PinkPos Alkalmazás
                    </Link>
                </div>
            </div>
        </div>}
    </div>
}

const DeviceManagementRender = () => {
    const {isSidebarOpen} = React.useContext(SidebarContext)
    const [openDevice, setOpenDevice] = useState(false)
    const translate = useTranslate();
    return ((GetPermission("DEVICE", "LIST") || GetPermission("ORDER_PRINTER", "LIST")) && <div key={"deviceManagementMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
            <MenuItem
                onClick={() => setOpenDevice(!openDevice)}>
                {openDevice ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}
                {translate('device.menuTitle')}
            </MenuItem>
            {openDevice &&
                <div key={"deviceManagementMenuItems"} style={{paddingLeft: '10px'}}>
                    <Menu.ResourceItem name="device"/>
                    <Menu.ResourceItem name="v1/order/printer"/>
                </div>
            }
        </div>
    )
}

const CorporateAdminRender = () => {
    const {isSidebarOpen} = React.useContext(SidebarContext)
    const translate = useTranslate();
    const [openCorporateAdmin, setOpenCorporateAdmin] = useState(false)
    const visible = GetPermission("PURCHASE", "LIST", true) ||
        GetPermission("PURCHASE", "STORNO", true) ||
        GetPermission("SHIFT", "CLOSE", true) ||
        GetPermission("SHIFT", "LIST", true) ||
        GetPermission("LICENSE", "GET", true) ||
        GetPermission("LICENSE", "UPDATE", true)

    return <div key={"corporateAdminMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
        {visible &&
            <Tooltip title={isSidebarOpen ? "" : translate('menu.corporateAdmin')} placement={"right"}>
                <MenuItem sx={{paddingLeft: isSidebarOpen ? '16px' : '0px'}}
                          onClick={() => setOpenCorporateAdmin(!openCorporateAdmin)}>
                    {openCorporateAdmin ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}
                    {isSidebarOpen ? translate('menu.corporateAdmin') : <AdminPanelSettingsIcon/>}
                </MenuItem>
            </Tooltip>}
        {openCorporateAdmin && <div key={"corporateAdminMenuItems"} style={{paddingLeft: '10px'}}>
            <Menu.ResourceItem name={"manageLicense"}/>
            <Menu.ResourceItem name={"purchase"}/>
            <Menu.ResourceItem name={"shift"}/>
            <Menu.ResourceItem name={"finance"}/>
            <Menu.ResourceItem name={"voucherGenerator"}/>
            {/*<Menu.ResourceItem name={"transactionUpload"}/>*/}
        </div>}
    </div>
}

export const PinkMenu = (props) => {
    const [isShowNTAK, setIsShowNTAK] = useState(true)
    useEffect(() => {
        dataProvider.get('ntak/pos-sets')
            .then((value) => {
                value.data && value.data.list.length > 0 ? setIsShowNTAK(true) : setIsShowNTAK(false)
            })
    }, [])

    return (
        <Menu {...props}>

            <AdministrationRender/>

            {GetPermission("PRODUCT", "LIST") && <Menu.ResourceItem name="product"/>}

            <PriceListRender/>

            {GetPermission("USER", "LIST") && <Menu.ResourceItem name="user"/>}

            {GetPermission("GROUP", "LIST") && <Menu.ResourceItem name="group"/>}

            {GetPermission("CONFIG", "LIST") && <Menu.ResourceItem name="configToPosSet"/>}

            <DeviceManagementRender/>

            {GetPermission("RMS_ERROR_MESSAGES", "LIST") && isShowNTAK && <Menu.ResourceItem name="rMSErrorMessages"/>}

            {GetPermission("REPORT", "CREATE") && <Menu.ResourceItem name="report"/>}

            {GetPermission("DASHBOARD", "LIST") && <Menu.ResourceItem name="dashboard"/>}

            <WarehouseRender/>

            <Menu.ResourceItem name="license"/>

            <UserManualRender/>

            <CorporateAdminRender/>
        </Menu>
    )
};