import Privileges from "./Privileges";
import privileges from "./Privileges";

const getValidBusinessUnitPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.BusinessUnit.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Create.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Delete.id)
            break;
        default: break;
    }
}

const getValidProductPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Product.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.Product.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.Product.Create.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.Product.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.Product.Delete.id)
            break;
        default: break;
    }
}

const getValidPrinterPrivileges =(method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.OrderPrinter.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.OrderPrinter.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.OrderPrinter.Create.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.OrderPrinter.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.OrderPrinter.Delete.id)
            break;
        default: break;
    }
}

const getValidGroupPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.PosHierarchy.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.PosHierarchy.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.PosHierarchy.Update.id)
            break;
        default: break;
    }
}

const getValidDevicePrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Device.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.Device.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.Device.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.Device.Delete.id)
            break;
        default: break;
    }
}

const getValidUserPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.User.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.User.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.User.Create.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.User.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.User.Delete.id)
            break;
        default: break;
    }
}

const getValidUserInvitePrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Invitation.id)
    switch (method) {
        case 'INVITE':
            privileges.push(Privileges.Privilege.Admin.Invitation.Send.id)
            break;
        default: break;
    }
}

const getValidPriceListPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.PriceList.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.PriceList.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.PriceList.Create.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.PriceList.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.PriceList.Delete.id)
            break;
        default: break;
    }
}

const getValidPriceListToPosSetPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.PriceListToPosSet.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.PriceListToPosSet.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.PriceListToPosSet.Update.id)
            break;
        default: break;
    }
}

const getValidReportPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Report.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.Report.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.Report.Create.id)
            break;
        default: break;
    }
}

const getValidShiftPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.CorporateAdmin.Shift.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.CorporateAdmin.Shift.List.id)
            break;
        case 'CLOSE':
            privileges.push(Privileges.Privilege.CorporateAdmin.Shift.Close.id)
            break;
        default: break;
    }
}

const getValidLicensePrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.CorporateAdmin.License.id)
    switch (method) {
        case 'GET':
            privileges.push(Privileges.Privilege.CorporateAdmin.License.Get.id)
            break;
        case 'UPDATE':
            privileges.push(Privileges.Privilege.CorporateAdmin.License.Update.id)
            break;
        default: break;
    }
}

const getValidPurchasePrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.CorporateAdmin.Purchase.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.CorporateAdmin.Purchase.List.id)
            break;
        case 'STORNO':
            privileges.push(Privileges.Privilege.CorporateAdmin.Purchase.Storno.id)
            break;
        default: break;
    }
}

const getValidPosWithPriceListPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Pos.id)
    privileges.push(Privileges.Privilege.Admin.Pos.PriceList.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.Pos.PriceList.Get.id)
            break;
        default: break;
    }
}

const getValidDashboardPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Dashboard.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.Dashboard.Get.id)
            break;
        default: break;
    }
}

const getValidCompanyPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Company.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.Company.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.Company.Update.id)
            break;
        default: break;
    }
}

const getValidConfigPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.PosConfig.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.PosConfig.Get.id)
            break;
        case 'UPDATE':
            privileges.push(Privileges.Privilege.Admin.PosConfig.Update.id)
            break;
        default: break;
    }
}

const getValidWarehouseProductPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.WarehouseProduct.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.WarehouseProduct.Get.id)
            break;
        case 'GET':
            privileges.push(Privileges.Privilege.Admin.WarehouseProduct.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.WarehouseProduct.Create.id)
            break;
        case 'UPDATE':
            privileges.push(Privileges.Privilege.Admin.WarehouseProduct.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.WarehouseProduct.Delete.id)
            break;
    }
}

const getValidRMSErrorMessagesPrivileges = (method, privileges) => {
    //TODO BE jog felvétele
    privileges.push(Privileges.Privilege.Admin.BusinessUnit.id)
    switch (method) {
        case 'LIST' || 'GET':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Get.id)
            break;
        default: break;
    }
}

export const getValidPrivilegesIds = (resource, method, corporateAdmin) => {
    resource = resource.toUpperCase()
    method = method.toUpperCase()
    let privileges = []
    //base privilege can access anything
    privileges.push(Privileges.Privilege.id)
    if (corporateAdmin) {
        privileges.push(Privileges.Privilege.CorporateAdmin.id)
        switch (resource){
            case "PURCHASE":
                getValidPurchasePrivileges(method, privileges)
                break;
            case "SHIFT":
                getValidShiftPrivileges(method, privileges)
                break;
            case "LICENSE":
                getValidLicensePrivileges(method, privileges)
                break;
            default: break;
        }

    } else {
        privileges.push(Privileges.Privilege.Admin.id)
        switch (resource) {
            case 'BUSINESS_UNIT':
                getValidBusinessUnitPrivileges(method, privileges)
                break
            case "GROUP":
                getValidGroupPrivileges(method, privileges)
                break
            case "DEVICE":
                getValidDevicePrivileges(method, privileges)
                break
            case "PRODUCT":
                getValidProductPrivileges(method, privileges)
                break
            case "USER":
                getValidUserPrivileges(method, privileges)
                break
            case "USERINVITE":
                getValidUserInvitePrivileges(method, privileges)
                break
            case "PRICELIST":
                getValidPriceListPrivileges(method, privileges)
                break
            case "PRICELIST_TO_POSSET":
                getValidPriceListToPosSetPrivileges(method, privileges)
                break
            case "REPORT":
                getValidReportPrivileges(method, privileges)
                break
            case "POS_WITH_PRICELIST":
                getValidPosWithPriceListPrivileges(method, privileges)
                break
            case "DASHBOARD":
                getValidDashboardPrivileges(method, privileges)
                break
            case "COMPANY":
                getValidCompanyPrivileges(method, privileges)
                break
            case "CONFIG":
                getValidConfigPrivileges(method, privileges)
                break
            case "WAREHOUSE":
                getValidWarehouseProductPrivileges(method, privileges)
                break
            case "RMS_ERROR_MESSAGES":
                getValidRMSErrorMessagesPrivileges(method, privileges)
                break
            case "ORDER_PRINTER":
                getValidPrinterPrivileges(method, privileges)
                break
            default: break
        }
    }
    return privileges
}

export const setBusinessUnitPermissions = (privileges, resources) => {
    resources.businessUnit.hasList = false
    resources.businessUnit.hasEdit = false
    const privilegeIds = privileges.map(it => it.id)
    const privilegeNames = privileges.map(it => it.name)
    const businessUnitBasePrivileges = [Privileges.Privilege.id, Privileges.Privilege.children.BusinessUnit.id]
    const businessUnitGetPrivileges = [...businessUnitBasePrivileges, Privileges.Privilege.children.BusinessUnit.children.Get.id]
    const businessUnitUpdatePrivileges = [...businessUnitBasePrivileges, Privileges.Privilege.children.BusinessUnit.children.Update.id]
    for (let i = 0; i < privilegeIds.length; i++) {
        if (businessUnitGetPrivileges.includes(privilegeIds[i])) {
            resources.businessUnit.hasList = true
        }

        if (businessUnitUpdatePrivileges.includes(privilegeIds[i])) {
            resources.businessUnit.hasEdit = true
        }
        console.log("xxxx", resources)
    }
}